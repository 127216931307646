@import '../../scss/settings.scss';
.gen-footer {
    background-color: #f3f3f3;
    bottom: 0;
    left: 0;
    right: 0;
    a {
        border: 1px solid white;
        padding: .25em .25em;
        border-radius: $corner-radius;
        color: white;
        cursor: pointer;
        &:hover {
            background-color: white;
            color: $site-primary;
        }
    }
    .footer-section {
        text-align: center;
        padding: 10px;
        position: relative;

        .small-logo {
            width: 36px;
        }
        .goto-top{
            color: $site-primary;
            //position: absolute;
            right: 0;
            background: none!important;
            border: none;
            padding: 0!important;
            /*optional*/
            //font-family: arial, sans-serif;
            /*input has OS specific font-family*/
            //color: #069;
            text-decoration: none;
            cursor: pointer;
        }

        .list-style2 {
            padding: 0;
            list-style: none;
            display: flex;
            justify-content: center;
            width: 100%;
            text-align: center;
            li {
                margin: 0 12px 0px 12px;
                display: flex;
            }
            a {
                text-decoration: none;
            }
        }
    
    }
    .links {
        background-color: $site-primary;
    }
}