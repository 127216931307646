@use "sass:math";
@import 'src/scss/settings.scss';

// @import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

$header-height: 60px;
$nav-height: 0px;

.gen-header {
    height: $header-height + $nav-height;
    color: $site-primary-text;
    line-height: $header-height;
    margin-bottom: 10px;
    font-size: 16px;
    z-index: 99;
    background-color: $site-primary;
    //box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.67);

    overflow: hidden;
    @include respond-to(medium) {
        margin-bottom: 10px;
        height: 80px;
        line-height: 80px;
    }
    @include respond-to(small) {
        margin-bottom: 0px;
        height: 60px;
        line-height: 60px;
    }
    &.fixed {
        position: static;
        top: 0;
    }
    .header-container {
        display: flex;
        .site,
        a {
            display: flex;
            flex-direction: row;
            color: #fff;
            text-decoration: none;            
            img {
                display: block; 
                align-self: center;
                height: $header-height * 0.7;
                transform-origin: center left; // margin-right: 130px;
                margin-right: 33px;
                @include respond-to(medium) {
                    height: 70px * 0.7
                }
                @include respond-to(small) {
                    height: 60px * 0.7
                }

                /* IE11 hack */
                _:-ms-fullscreen, :root & {
                    width: 50%
                }
            }
            .icon {
                display:inline-block;
                padding-right: 5px;
            }
            .tagline {
            
                font-size: 18px;
                @include respond-to(medium) {
                    font-size: 16px;
                    margin-left: 18px;
                }
                @include respond-to(small) {
                    font-size: 16px;
                    margin-left: 16px;
                }
            }
        }
        .options {
            display: block;
            padding: 0;
            margin: 0;
            margin-left: auto;
            white-space: nowrap;
            font-size: 18px;
            li {
                padding: 0;
                margin: 0;
                list-style: none;
                a {
                    display: block;
                    height: 100%;
                    padding-left: 0 math.div($header-height, 4);
                    text-decoration: none; 
                }
                // &:hover {
                //     background-color: $site-primary;
                //     a {
                //         color: contrast-color($site-primary, white, black);
                //     }
                // }
                .option-text {
                    @include respond-to(medium) {
                        display: none;
                    }
                }
                .option-icon {
                    display: none;
                    font-size: 28px;
                    
                    @include respond-to(medium) {
                        display: inline;
                    }
                }
            }
        }
    }
    &.fixed {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        &+div {
            margin-top: $header-height + $nav-height + 20;
        }
    }
    .nav-main {
        line-height: $nav-height;
        height: $nav-height;
        background-color: $site-secondary;
        @include respond-to(small) {
            font-size: 14px;
        }
        .menu-items {
            display: block;
            padding: 0;
            margin: 0 -10px;
            display: flex;
            overflow-x: auto;
            overflow-y: hidden;
            -webkit-overflow-scrolling: touch;
            white-space: nowrap;
            li {
                padding: 0;
                margin: 0;
                list-style: none;
                &.right {
                    margin-left: auto;
                }
                a {
                    display: block;
                    height: $nav-height;
                    padding: 0 10px;
                    text-decoration: none;
                    color: white;
                    font-family: 'Fira Sans', sans-serif;
                    &:hover {
                        background-color: $body-background;
                        color: $site-primary;
                    }
                }
            }
        }
    }
}