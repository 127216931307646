@import "./mixins.scss";

$site-primary: #311b92;    //#023435; // Dark green
$site-primary-text: white; // white text
$site-secondary: #FCCB1A; //#68bd5b; Light green
$site-highlight: #f68b1f; // Orange

$font-family-regular: 'Ubuntu', sans-serif;

$body-background: white; //desaturate(mix($site-primary, white, 10%), 0%); 
$body-color: contrast-color($body-background, #eee, #282828);
$body-font-family: $font-family-regular;
$body-font-size: 1em;
$subscript-font-size: .75rem;  

$well-background: #f3f3f3;
$table-border: 1px solid #BCBDC0;
$icon-color: #ccc;
$info-color: #34baeb;


$heading-font-family: $font-family-regular;

$form-label-color: contrast-color($body-background, $site-secondary, $site-primary);

$input-color: black;
$input-background:white;
$input-border-color: #ced4da; //#6e6e6e
$input-border: 1px solid $input-border-color;
$input-border-hover: solid 1px $site-primary;
$input-border-focus: solid 1px $site-primary;
$input-max-width: 400px;
$input-border-radius: 5px;  
$input-text-color: #2e2e2e;
$input-fieldset: #406767;


$button-font-family: $font-family-regular; 

$legend-font-family: $font-family-regular;

$corner-radius: 5px;