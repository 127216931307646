$breakpoints: ( 'small': ( max-width: 480px), 'medium': ( max-width: 768px), 'large': ( max-width: 992px), 'xlarge': ( max-width: 1200px));
@mixin respond-to($name) {
    // If the key exists in the map
    @if map-has-key($breakpoints, $name) {
        // Prints a media query based on the value
        @media #{inspect(map-get($breakpoints, $name))} {
            @content;
        }
    } // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "+"Please make sure it is defined in `$breakpoints` map.";
    }
}

@mixin no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@function contrast-color($color, $light: #ffffff, $dark: #000000) {
    @if (lightness($color) > 50) {
        @return $dark; // Lighter backgorund, return dark color
    }
    @else {
        @return $light; // Darker background, return light color
    }
}

@mixin triangle($direction, $color, $size: 1em) {
    display: block;
    height: 0;
    width: 0;
    @if $direction=='top' {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
    }
    @else if $direction=='right' {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
    }
    @else if $direction=='bottom' {
        border-top: $size solid $color;
        border-right: $size solid transparent;
        border-left: $size solid transparent;
    }
    @else if $direction=='left' {
        border-top: $size solid transparent;
        border-right: $size solid $color;
        border-bottom: $size solid transparent;
    }
    @else if $direction=='top-left' {
        border-top: $size solid $color;
        border-right: $size solid transparent;
    }
    @else if $direction=='top-right' {
        border-top: $size solid $color;
        border-left: $size solid transparent;
    }
    @else if $direction=='bottom-left' {
        border-bottom: $size solid $color;
        border-right: $size solid transparent;
    }
    @else if $direction=='bottom-right' {
        border-bottom: $size solid $color;
        border-left: $size solid transparent;
    }
}

/// Test if `$value` is a valid direction
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
    $is-keyword: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
    $is-angle: type-of($value)=='number' and index('deg' 'grad' 'turn' 'rad', unit($value));
    @return $is-keyword or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
/// @throw Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.;
@function legacy-direction($value) {
    @if is-direction($value)==false {
        @error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
    }
    $conversion-map: ( to top: bottom, to top right: bottom left, to right top: left bottom, to right: left, to bottom right: top left, to right bottom: left top, to bottom: top, to bottom left: top right, to left bottom: right top, to left: right, to left top: right bottom, to top left: bottom right);
    @if map-has-key($conversion-map, $value) {
        @return map-get($conversion-map, $value);
    }
    @return 90deg - $value;
}

@mixin linear-gradient($direction, $color-stops...) {
    // Direction has been omitted and happens to be a color-stop
    @if is-direction($direction)==false {
        $color-stops: $direction, $color-stops;
        $direction: 180deg;
    }
    background: nth(nth($color-stops, 1), 1);
    background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
    background: linear-gradient($direction, $color-stops);
}