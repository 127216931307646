@import "../../scss/settings.scss";
.img-header {    
    background-size: cover;
    background-position-y: center;
    background-position-x: center;
    padding: 5px 0; //margin-top: -20px;
    margin-bottom: 20px;
    overflow: hidden;
    h1 {
        padding: 10px 10px 10px 10px;
        margin: 0 0 0 -10px; //text-shadow: 0 0 10px black, 0 0 5px black; //background-color: rgba(0, 0, 0, 0.3);
        display: block;
        @include respond-to(small) {
            font-size: 2rem;
        }
    };
    
}

.gen-layout {
    //min-height: calc(100vh - 56px);  
    flex:1;  
    padding-bottom: 30px; //background-color: #e7eef3;
    @include respond-to(medium) {
        padding-bottom: 10px;
    }
    .spacer {
        height: 20px;
    }
}

.gen-progress {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    height: 70px;
    @include respond-to(medium) {
        margin-bottom: 10px;
    }
    li {
        text-align: center;
        list-style: none;
        flex-grow: 1;
        flex-basis: 0;
        display: flex;
        align-items: center;
        position: relative;
        padding: 10px;
        margin: 5px 0;
        @include respond-to(small) {
            font-size: 10px;
            padding: 3px;
        }
        .inner {
            text-align: center;
            width: 100%;
        }
        .inner.index{
            display: none;
        }
        &.past {
            
        }
        &.present {

            border-bottom: solid 4px $site-highlight; 
        }
        &.future {
            opacity: 1;
            color: #6d6d6d;
        }
    }
}

.gen-messagebar {
    padding: 10px 0;
    background-color: #2cb94b;
    color: white;
    font-size: 16px;
    margin-bottom: 20px;

    a {
        color: white;
        border: none;
        display: inline-block;
        margin-left: 0.5em;

        &:hover{
            color: white;
        }
    }
}