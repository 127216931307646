@import "./settings.scss";
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/line-awesome/1.2.1/line-awesome/css/line-awesome.min.css");
//          "https://maxst.icons8.com/vue-static/landings/line-awesome/font-awesome-line-awesome/css/all.min.css"


@import "normalise";
// @import "base"; 
// @import "layout";
// @import "grid";
// @import "forms";
//@import "icons";

.info-circle{
    color: #34baeb;
}

.page-container{
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.small-text{
    font-size: $subscript-font-size;
}